<template>
  <div>
    <div style="background-color: #80ffdb; background-color: rgb(240, 255, 255);  padding: 10px">
      <section class="background_video_container">
        <video autoplay loop controls muted class="fullscreen">
          <source
            :src="require('@/assets/videos/newcsm.mp4')"
            type="video/mp4"
          />
          <!-- Add additional source tags for different video formats if needed -->
        </video>
      </section>
    </div>

    <div class="back_car">
      <router-link to="/appointment" class="back_car_circle">
        <div class="h5-element">
          Book An <br />
          Appointment
          <font-awesome-icon icon="fa-solid fa-arrow-right" />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "HomeBackCar",
};
</script>

<style scoped>
.back_car {
  background-image: url("../../assets/images/gallary/car13.jpg");
  background-position: center;
  background-size: cover;
  height: 100vh;
  background-attachment: fixed;
}

@media screen and (max-width: 980px) {
  .back_car {
    background-attachment: scroll;
  }
}

@media screen and (max-width: 770px) {
  .back_car {
    height: 70vh;
  }
  .back_car_circle .h5-element {
    padding: 65px 35px !important;
  }
}

.background_video_container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 0px 30px;
}

.background_video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

video.fullscreen {
  position: absolute;
  z-index: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}
/* video.fullscreen::-webkit-media-controls {
    display: none !important;
  } */
.background_video_container {
  position: relative;
  display: grid;
  place-items: center;
  height: 70vh;
  width: 70vw;
  margin: 0 auto;
  background: #ccc;
  margin-bottom: 20px;
  margin-top: 20px;
}

.back_car_circle {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back_car_circle .h5-element {
  /* background-color: red; */
  /* background-color: #80ffdb; */
  /* background-color: #17db5f; */
  /* background-color: #ff8800; */
  background-color: rgb(240, 255, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 90px 20px;
  border-radius: 50%;
  /* color: white; */
  color: #011627;
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.5s ease;
  cursor: pointer;
  font-size: 20px;
  width: 230px;
  text-align: left;
}

.back_car_circle .h5-element:hover {
  background-color: black;
  color: white;
}
</style>
