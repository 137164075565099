<template>
  <div>
    <div class="appiontment_contain">
      <div class="appiontment_info">
        <h2>GET <span>FREE</span> Appointment</h2>

        <v-form ref="form" @submit.prevent="handleSubmit">
          <h4>personal information</h4>

          <div class="appiontment_inputs">
            <v-text-field
              :rules="rules1"
              required
              v-model="form.name"
              type="text"
              label="name"
            ></v-text-field>

            <v-text-field
              :rules="rules2"
              required
              v-model="form.email"
              type="email"
              label="email"
            ></v-text-field>
          </div>

          <div class="appiontment_photo">
            <vue-tel-input
              style="padding: 15px 0 15px 0 !important; color: #000 !important"
              :value="form.phone"
              @input="onInput"
            ></vue-tel-input>
          </div>

          <br />

          <h4>Vehicle Information</h4>

          <div class="appiontment_inputs">
            <v-text-field
              :rules="rules1"
              required
              v-model="form.carMake"
              type="text"
              label="Car make"
              hint="Make of car (e.g Toyota, Range Rover)..."
              name="car-make"
            ></v-text-field>

            <v-text-field
              :rules="rules1"
              required
              v-model="form.carModel"
              type="text"
              label="Choose Model"
              hint="Choose Model E.g Camry, Velar.."
              name="car-model"
            ></v-text-field>

            <v-select
              v-model="form.carYear"
              :rules="rules1"
              label="Car Year"
              :items="yearOptions"
            ></v-select>

            <!-- 
            <select
              required
              v-model="form.carYear"
              placeholder="Year"
              name="car-year"
            >
              <option value="" disabled selected>Select Year</option>
              <option
                style="color: black !important"
                v-for="val in yearOptions"
                :key="val"
                :value="val"
              >
                {{ val }}
              </option>
            </select> -->
          </div>

          <div class="appiontment_photo">
            <v-file-input
              :v-model="form.carPhoto"
              @change="handleFileUpload"
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="GIve use a view of your car"
              hint="image should not be above 5 mb"
            >
            </v-file-input>
          </div>

          <div class="appiontment_descrip">
            <v-textarea
              :rules="rules1"
              required
              v-model="form.carCase"
              label="GIve us a discription of what you want.."
            ></v-textarea>
          </div>

          <br />

          <h4>choose date and time</h4>

          <div class="appiontment_date">
            <VueDatePicker
              required
              v-model="form.date"
              :min-date="new Date()"
              :is-24="false"
              :format="format"
              aria-placeholder="Select date"
              placeholder="Pick your appointment date"
            ></VueDatePicker>
          </div>

          <br />

          <div class="form_warn">
            <font-awesome-icon
              icon="fa-solid fa-circle-exclamation"
              :style="{ color: 'red' }"
            />
            <p>
              Please note that the date and time you requested may not be
              available. We will contact you to confirm your actual appointment
              details.
            </p>
            <!-- <img :src="previewImageUrl" alt="" /> -->
          </div>

          <button type="submit" :disabled="loading">
            <span v-if="!loading">Book now</span><span v-else>Booking... </span>
            <font-awesome-icon v-if="!loading" icon="fa-solid fa-arrow-right" />
            <v-progress-circular
              v-else
              indeterminate
              :width="5"
            ></v-progress-circular>
          </button>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from "@/utils/axiosInstance";

export default {
  name: "Appiontment",
  data() {
    return {
      loading: false,
      rules1: [(value) => !!value || "Required."],

      rules2: [
        (value) => !!value || "Required.",

        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      ],

      rules3: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      phone: "",
      form: {
        name: "",
        phone: "",
        email: "",
        carMake: "",
        carModel: "",
        carYear: "",
        carPhoto: "",
        carCase: "I need a paint job",
        date: "",
        time: "",
        formatedTime: "",
      },

      selectedYear: null,
      yearOptions: [],
      selectedFile: "",
      previewImageUrl: "",
    };
  },

  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (event) => {
        console.log(event.target.result, "second");
        this.compressAndSetPreviewImage(event.target.result);
      };
      reader.readAsDataURL(this.selectedFile);
    },
    compressAndSetPreviewImage(dataUrl) {
      const img = new Image();
      img.src = dataUrl;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = 800; // Set the desired width
        canvas.height = (img.height / img.width) * canvas.width;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const compressedDataUrl = canvas.toDataURL("image/jpeg", 0.3); // Set the desired compression quality (0.7 in this example)
        if (this.isFileSizeExceeded(compressedDataUrl)) {
          alert("File is too large. Maximum size allowed is 2MB.");
        } else {
          this.previewImageUrl = compressedDataUrl;
          console.log(this.previewImageUrl, "second");
        }
      };
    },
    isFileSizeExceeded(dataUrl) {
      const byteString = atob(dataUrl.split(",")[1]);
      const fileSize = byteString.length;
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      return fileSize > maxSize;
    },

    async handleSubmit() {
      const { valid } = await this.$refs.form.validate();

      if (!this.form.date) {
        this.$toast.error("Please pick your appointment date and time");
        return;
      }

      if (valid) {
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append("name", this.form.name);
          formData.append("phone", this.form.phone);
          formData.append("email", this.form.email);
          formData.append("carMake", this.form.carMake);
          formData.append("carModel", this.form.carModel);
          formData.append("carYear", this.form.carYear);
          formData.append("carCase", this.form.carCase);
          formData.append("date", this.form.date);
          formData.append("time", this.form.time);
          formData.append("formatedTime", this.form.formatedTime);
          formData.append("file", this.selectedFile); // Update this line

          const response = await axiosInstance.post("/emailsend/", formData);

          if (response.status == 200) {
            console.log("Email sent successfully!");
            this.$toast.success(
              "Your appointment have been made, check your email for confirmations"
            );
            this.resetForm();
            this.loading = false;
            // Handle success, e.g., show a success message to the user
          } else {
            console.error("Failed to send email.");
            this.$toast.error("An error occured");
            this.loading = false;
            // Handle error, e.g., show an error message to the user
          }
        } catch (error) {
          console.error("Error sending email:", error);
          this.$toast.error("An error occured");
          this.loading = false;
          // Handle error, e.g., show an error message to the user
        }
      }
    },
    onInput(phone, phoneObject, input) {
      if (phoneObject?.formatted) {
        this.form.phone = phoneObject.formatted;
      }
    },

    resetForm() {
      this.$refs.form.reset();
    },

    format(date) {
      const time = date.getTime();
      const timestamp = time;
      const date1 = new Date(timestamp);
      const hours = date1.getHours();
      const minutes = date1.getMinutes();
      const seconds = date1.getSeconds();

      const day = date1.getDate();
      const month = date1.toLocaleString("default", { month: "long" });
      const year = date1.getFullYear();

      const formattedDate = `${day}th of ${month} ${year}`;

      const formattedTime = `${hours}:${minutes}:${seconds}`;

      this.form.formatedTime = `Selected date is ${formattedDate} by ${formattedTime}`;

      return `Selected date is ${formattedDate} by ${formattedTime}`;
    },
  },

  mounted() {
    // Generate an array of years (e.g., from 1900 to the current year)
    // Generate an array of years in descending order
    const currentYear = new Date().getFullYear();
    const startYear = 1900;
    const years = Array.from(
      { length: currentYear - startYear + 1 },
      (_, index) => currentYear - index
    );

    // Convert the years array to an array of year strings
    this.yearOptions = years.map((year) => year.toString());

    console.log(this.yearOptions);
  },
};
</script>

<style scoped>
/* Add the styles from the original CSS file */
.vue-tel-input .vti__input {
  /* color: #f2f2f2; */
  color: #011627;
}
.appiontment_contain {
  /* max-width: 1480px; */
  /* margin: 0px auto; */
  /* background-color: #023047; */
  background-color: #80ffdb;
  background-color: rgb(240, 255, 255);
  color: #011627;
  /* color: white; */
}

.appiontment_info {
  max-width: 1480px;
  margin: 0px auto;
  padding-top: 100px;
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;

  padding-bottom: 50px;
}

.appiontment_info h2 span {
  color: red;
}

.appiontment_info h2 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 45px;
}

.appiontment_info form h4 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 20px;
  color: #011627;
}

.appiontment_inputs {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  justify-content: space-between;
}

.appiontment_info select option {
  font-size: 14px;
  padding: 8px 12px;
}

.appiontment_info select option:checked {
  background-color: #f2f2f2;
}
.appiontment_info input,
select,
.appiontment_info textarea {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  padding: 20px 10px 20px 0px;
  width: 100%;
  margin-bottom: 20px;
  transition: 0.5s ease;
  outline: none;
}

.appiontment_info input:focus,
.appiontment_info select:focus,
.appiontment_info textarea:focus {
  outline: none;
}

.appiontment_info input:focus:invalid,
.appiontment_info select:focus:invalid,
.appiontment_info textarea:focus:invalid {
  border: 1px solid red;
  outline: none;
}

.appiontment_info textarea {
  margin-top: 20px;
}

.appiontment_info input:hover,
.appiontment_info textarea:hover {
  border-bottom: 2px solid red;
}

.appiontment_info input::placeholder,
.appiontment_info textarea::placeholder {
  font-weight: 600;
  letter-spacing: 1px;
  font-family: inherit;
}

.appiontment_date {
  display: flex;
  gap: 20px;
}

.form_warn {
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: #0d0d0dfb;
  padding: 5px 20px;
  margin-bottom: 10px;
  color: whitesmoke;
}

@media screen and (max-width: 770px) {
  .appiontment_info {
    padding-top: 200px;
    margin-top: -30px;
  }
  .appiontment_inputs {
    flex-direction: column;
    align-items: inherit;
  }
  .appiontment_date {
    flex-direction: column;
  }
  .form_warn {
    display: flex;
    /* align-items: ; */
  }
  .form_warn p {
    font-size: 12px;
  }
  .warn_icon {
    font-size: 50px;
  }
}

.appiontment_info button {
  padding: 20px 30px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  background-color: #e00f0f;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.5s ease;
  cursor: pointer;
  font-weight: 400;
  border: none;
}

.appiontment_info button:hover {
  background-color: white;
  color: black;
}
</style>
