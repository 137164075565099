<template>
  <main className="home">
    <HomeIntro />
    <Gallery1 />
    <!-- <Tabs /> -->
    <!-- <Gallery /> -->
    <HomeSection2 />

    <Ferrari />
    <!-- <Gallery1 /> -->
    <HomeService />

    <HomeBackCar />
    <HomeTestimonial />
    <Homeappiontment />
    <HomeFooter />
  </main>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import HomeIntro from "@/components/home/HomeIntro.vue";
import HomeSection2 from "@/components/home/HomeSection2.vue";
import Ferrari from "@/components/home/Ferrari.vue";
import HomeService from "@/components/home/HomeService.vue";
import HomeBackCar from "@/components/home/HomeBackCar.vue";
import HomeTestimonial from "@/components/home/HomeTestimonial.vue";
// import Homeappiontment from "@/components/home/Homeappiontment.vue";
import Homeappiontment from "@/components/appiontment/Appiontment.vue";
import HomeFooter from "@/components/home/HomeFooter.vue";
import Gallery1 from "@/components/gallery/Gallery1.vue";
import Gallery from "@/components/gallery/Gallary.vue";
import Tabs from "@/components/gallery/Tabs.vue";
export default {
  name: "HomeView",
  components: {
    Tabs,
    Gallery,
    Gallery1,
    HomeIntro,
    HomeSection2,
    Ferrari,
    HomeService,
    HomeBackCar,
    HomeTestimonial,
    Homeappiontment,
    HomeFooter,
  },
};
</script>
