<template>
  <div class="home_section4">
    <div class="section4_contain">
      <div class="section4_top">
        <AnimatedComponent :duration="450" animationType="slide-fade">
          <h2>100% APPROVED BY CUSTOMERS</h2>
        </AnimatedComponent>
        <div class="testimonal_cards">
          <AnimatedComponent
            :duration="450"
            animationType="slide-fade"
            class="card"
            v-for="test in testimonial"
            :key="test.name"
          >
            <font-awesome-icon
              icon="fa-solid fa-quote-left"
              :style="{ color: '#ff8800' }"
            />
            <br />
            <p>{{ test.detail }}</p>
            <br />
            <hr />
            <h4>{{ test.name }}</h4>
            <div class="elementor_star_rating">
              <font-awesome-icon
                icon="fa-solid fa-star"
                :style="{ color: '#ff8800' }"
              />
              <font-awesome-icon
                icon="fa-solid fa-star"
                :style="{ color: '#ff8800' }"
              />
              <font-awesome-icon
                icon="fa-solid fa-star"
                :style="{ color: '#ff8800' }"
              />
              <font-awesome-icon
                icon="fa-solid fa-star"
                :style="{ color: '#ff8800' }"
              />
              <font-awesome-icon
                icon="fa-solid fa-star"
                :style="{ color: '#ff8800' }"
              />
            </div>
          </AnimatedComponent>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
// import { testimonial } from "./constant";

const testimonial = [
  {
    name: "Claudiu Veres",
    detail: `“Hey, my name is Lorant Claudiu Veres The owner of the Passat with reg FV**LVC. I want to thank you for the repair, I'm very happy with the job it's was done on very high quality and professional. Thanks to Cosmin Paint& Body. Regards
Regards, Claudiu Veres
”`,
  },
  {
    name: "JOHNATHAN TAYLOR",
    detail: `“Professionalism, Punctuality, Quality, Value. Complete Automotive is my go-to car service vendor. They’re professional, very good at what they do, and reasonably priced. We have 2 old-ish cars, and Complete Automotive always gives them good care.”`,
  },
  {
    name: "JENNIFER SHEPPARD",
    detail: `“Love this place. People are very friendly and transparent with what’s going on. They always get the work done as fast as they can and as far as I can tell the work has always been top notch!”`,
  },
  // Add more testimonials if needed
];

export default {
  name: "HomeTestimonial",

  data() {
    return {
      testimonial,
    };
  },
};
</script>

<style scoped>
.section4_contain {
  /* background-color: #304f3f; */
  background-color: rgb(240, 255, 255);
  max-width: 1480px;
  margin: 0px auto;
  padding: 30px 30px;
  padding-bottom: 40px;
}

.section4_top {
  padding-bottom: 60px;
}

.section4_top h2 {
  font-size: 45px;
}

.testimonal_cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
}

.card {
  border: 1px solid #17171771;
  border-radius: 2px;
  padding: 30px 40px;
  padding-bottom: 60px;
  text-align: left;
}

.card p {
  color: #171717a1;
}

.elementor_star_rating {
  margin-top: 10px;
}

@media screen and (max-width: 920px) {
  .testimonal_cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .card:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }

  .card:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }

  .card:nth-child(3) {
    grid-area: 2 / 1 / 3 / 3;
  }
}

@media screen and (max-width: 700px) {
  .testimonal_cards {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr) repeat(2, 0fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    max-height: fit-content;
  }

  .card:nth-child(1) {
    grid-area: 1 / 1 / 2 / 6;
  }

  .card:nth-child(2) {
    grid-area: 2 / 1 / 3 / 6;
  }

  .card:nth-child(3) {
    grid-area: 3 / 1 / 4 / 6;
  }

  .section4_top h2 {
    font-size: 35px;
    margin-bottom: 10px;
  }
}
</style>
