<template>
  <div>
    <transition name="fade">
      <div class="home_contain">
        <div class="home_top">
          <div class="home_intro">
            <AnimatedComponent
              :duration="850"
              animationType="slide-fade"
              class="home_left"
            >
              <h2 class="home_h2">
                <span class="rainbow_text">Csm Paint & Body</span><br />
                We are made to be extraordinary transforming
                visions into reality
              </h2>

              <button class="spanAni">
                <router-link to="/appointment">
                  EXPLORE OUR SERVICES
                  <!-- <BsArrowRight fontSize="20" class="rightarrow" /> -->
                  <font-awesome-icon icon="fa-solid fa-arrow-right"
                /></router-link>
              </button>
            </AnimatedComponent>
            <div class="home_right"></div>
          </div>
        </div>
      </div>
    </transition>

    <div
      style="
        background-color: #6fffe9;
        background-color: rgb(249, 247, 247);
        color: #000;
      "
    >
      <div class="hero_bottom">
        <hr />
        <AnimatedComponent
          :duration="450"
          animationType="nested"
          class="home_info"
        >
          <!-- <FaPhone size="30" /> -->
          <font-awesome-icon icon="fa-solid fa-phone" />
          <AnimatedComponent :duration="850" animationType="slide-up">
            <h4><a href="tel:+07710607508">CALL US NOW</a></h4>
            <p>Feel free to call us. +07710607508</p>
          </AnimatedComponent>
        </AnimatedComponent>
        <hr />

        <AnimatedComponent
          :duration="450"
          animationType="nested"
          class="home_info second"
        >
          <router-link to="/appointment">
            <!-- <BsCalendar size="30" /> -->
            <font-awesome-icon icon="fa-regular fa-calendar" />
            <AnimatedComponent :duration="850" animationType="slide-up">
              <h4>GET FREE Appointment</h4>
              <p>Free Diagnosis & Brake Checks</p>
            </AnimatedComponent>
          </router-link>
        </AnimatedComponent>

        <hr />
        <AnimatedComponent
          :duration="450"
          animationType="nested"
          class="home_info border-right"
        >
          <a
            target="_blank"
            href="https://www.google.com/maps/place/10+Rosevale+Rd,+Newcastle+ST5+7EF,+UK/@53.0432751,-2.2501312,109m/data=!3m2!1e3!4b1!4m6!3m5!1s0x487a5d4126d00001:0x6d887eeec052dcf5!8m2!3d53.0432751!4d-2.2494875!16s%2Fg%2F11gxxgwjl9?entry=ttu"
          >
            <!-- <MdLocationOn size="30" /> -->
            <font-awesome-icon icon="fa-solid fa-map-location" />
            <AnimatedComponent :duration="850" animationType="slide-up">
              <h4>FIND US ON THE MAP</h4>
              <p>Newcastle Under Lime ST57EF unit8 Rosevale Road</p>
            </AnimatedComponent>
          </a>
        </AnimatedComponent>
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import styles from "@/assets/styles/home/homeintro.module.css";
import AnimatedComponent from "../AnimatedComponent.vue";

export default {
  name: "HomeIntro",
  components: {
    AnimatedComponent, // Register the component
  },
  data() {
    return {
      isInView: false,
    };
  },
};
</script>

<style scoped>
.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease-out;
}

/* 
    If the element intersects with the viewport, the before-enter class is added.
  */
.enter {
  opacity: 1;
  transform: translateY(0px);
}

.home_top {
  background-image: url("../../assets/images/csmmain.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px auto;
  height: 100%;
  padding-top: 30px;
}

.home_contain {
  text-align: left;
  /* display: flex;
      align-items: left;
      flex-direction: column;
      justify-content: center; */
  height: 80vh;
  padding-top: 50px;
}

.home_intro {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.home_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
  height: 100%;
  padding: 10px 20px;
}

.home_left h2 {
  font-size: 40px;
  text-align: left;
  margin-top: -70px;
  font-weight: 800;
  /* color: rgb(8, 55, 79); */
  /* color: #6fffe9; */
  color: #011627d0;
}

.home_left h2 span {
  /* background-image: linear-gradient(
    to left,
    violet,
    indigo,
    blue,
    green,
    yellow,
    orange,
    red
  );
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text; */
  font-size: 52px;
  color: rgb(247, 247, 247);
  color: #ffd;
}

.home_left button {
  max-width: fit-content;
  /* background-color: #008bf5; */
  background-color: rgb(247, 247, 247);
  /* background-color: #eebbc3; */
  /* color: white; */
  color: black;
  padding: 20px;
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  transition: 0.5s ease;
  letter-spacing: 4px;
}

.home_left button:hover {
  /* background-color: #32dbc9; */
  background-color: #6fffe9;
  /* color: white; */
}

.hero_bottom {
  max-width: 1480px;
  margin: 0px auto;
}

@media screen and (max-width: 1020px) {
  .home_intro {
    flex-direction: column;
  }

  .home_left {
    max-width: 100%;
  }

  .home_left h2 {
    line-height: 50px;
  }
}

@media screen and (max-width: 750px) {
  .home_left h2 {
    font-size: 25px;
  }
}

.hero_bottom {
  display: flex;
  padding: 0px 20px;
  margin-top: -2px;
  text-align: left;
  /* margin-bottom: 10px; */
}

.home_info {
  width: 33.33333%;
  padding: 25px 37px;
}

.home_info {
  width: 33.33333%;
  padding: 25px 37px;
  border-left: 2px solid #000; /* Adjust the color and size as desired */
}

.border-right {
  border-right: 2px solid #000;
}

.home_info h4 {
  margin-bottom: 2px;
}

.home_info p {
  margin-top: 2px;
}

.home_info h4,
.home_info p {
  width: initial;
}

@media screen and (max-width: 1020px) {
  .home_info {
    width: 33.33333%;
    padding: 25px 37px;
    border-left: 2px solid #000; /* Adjust the color and size as desired */
  }

  .border-right {
    border-right: 2px solid #000;
  }
  .hero_bottom {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    text-align: left;
  }

  .hero-bottom hr {
    width: 100%;
  }
}

@media screen and (max-width: 770px) {
  .hero_bottom {
    align-items: flex-start;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .home_info {
    padding-left: 0px;
    width: 80%;
    padding: 10px 37px;
    border-left: none;
    align-content: center;
    justify-content: center;
  }

  .border-right {
    border-right: none;
  }
  .home_info h4,
  .home_info p {
    width: initial !important;
  }

  .hero_bottom .second {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    width: 80vw;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
